import React from "react"

import AppBase from "../../util/app-base"
import DonationPageLayout from "../../components/spenden-page/donation-page-layout"

import { InternalRainbowLink } from "../../components/rainbow-link"

export default () => (
  <AppBase title="FLAGincluded - Spenden">
    <DonationPageLayout
      showPocket={false}
      leftButton={{
        enabled: false,
      }}
      labelText="Schritt 6"
      rightButton={{
        enabled: false,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2>Vielen herzlichen Dank!</h2>
        <InternalRainbowLink
          href="/"
          title="Zurück zur Startseite"
          style={{ marginTop: "20px" }}
        />
      </div>
    </DonationPageLayout>
  </AppBase>
)
